<template>
  <div>
    <price-action :id="id" />
  </div>
</template>

<script>
import PriceAction from "../components/PriceAction.vue";

export default {
  name: "PriceEdit",
  components: { PriceAction },
  data() {
    return {
      id: undefined,
    };
  },
  activated() {
    this.id = parseInt(this.$route.query.id);
  },
};
</script>
